import React from 'react'
import { Helmet } from 'react-helmet'
import LWCLayout from '../../layouts/LWCLayout'

const TrailblazerconnectIndex = () => {

  return (
    <LWCLayout slug={'trailblazerconnect'}>
        <Helmet>
            <title>Trailblazer Connect</title>
        </Helmet>

        <tm-page className="theme--salesforce" style={{display: 'block', transition: 'opacity 1000ms ease 0s', opacity: 1}}>
            <tm-hero-a 
                background="light" 
                img-align="center" 
                img-alt="Trailblazer Connect" 
                img-src="https://developer.salesforce.com/resources2/tb-connect/hero2.png" 
                subtitle="Trailblazer Connect creates pathways for anyone to build a career in the Salesforce ecosystem. Learn the skills to deliver success now and connect to mentorship and employment opportunities." 
                title="Trailblazer Connect"></tm-hero-a>
            
            <tm-section-a 
                description="Connect to learning, mentorship, and employment opportunities at every stage of your career path." 
                title="Resources for Your Career Journey">
                
                <tm-feature-grid-a>
                    <tm-grid-item 
                        cta-primary-href="https://trailhead.salesforce.com/trailblazerconnect/fundamentals/" 
                        cta-primary-target="true" 
                        cta-primary-title="REGISTER NOW" 
                        description="Kickstart your career with a free, virtual course to help you learn about Salesforce and earn your first credential." 
                        img-src="https://developer.salesforce.com/resources2/tb-connect/funda.png" 
                        img-alt="Salesforce Fundamentals"
                        title="Salesforce Fundamentals"></tm-grid-item>
                    
                    <tm-grid-item 
                        cta-primary-href="https://trailhead.salesforce.com/en/trailblazerconnect/directory/" 
                        cta-primary-target="true" 
                        cta-primary-title="BROWSE DIRECTORY" 
                        description="Find a virtual or in-person Salesforce training program to deepen your knowledge and skillset." 
                        img-src="https://developer.salesforce.com/resources2/tb-connect/wdd.png" 
                        img-alt="Trailblazer Workforce Development Directory"
                        title="Trailblazer Workforce Development Directory"></tm-grid-item>
                    
                    <tm-grid-item 
                        cta-primary-href="https://trailhead.salesforce.com/trailblazerconnect/mentorship/" 
                        cta-primary-target="true" 
                        cta-primary-title="FOR MORE INFORMATION" 
                        description="Get career advice, build your Salesforce network, and prepare for job interviews in a one-on-one or group setting." 
                        img-src="https://developer.salesforce.com/resources2/tb-connect/ment.png" 
                        img-alt="Trailblazer Mentorship"
                        title="Trailblazer Mentorship"></tm-grid-item>
                    
                    <tm-grid-item 
                        cta-primary-href="https://trailhead.salesforce.com/trailblazerconnect/careerfair" 
                        cta-primary-target="true" 
                        cta-primary-title="LEARN MORE" 
                        description="Learn from Salesforce career experts, network with local companies hiring, and meet fellow Trailblazers." 
                        img-src="https://developer.salesforce.com/resources2/tb-connect/r4.png" 
                        img-alt="Trailblazer Career Fairs"
                        title="Trailblazer Career Fairs"></tm-grid-item>
                    </tm-feature-grid-a>
            </tm-section-a>
                
            <tm-section-a background="dark" title="Trailblazer Connect in 2022">
                <tm-card-grid-a img-type="full">
                    <tm-grid-item img-src="https://developer.salesforce.com/resources2/tb-connect/s1.png"></tm-grid-item>
                    <tm-grid-item img-src="https://developer.salesforce.com/resources2/tb-connect/s2.png"></tm-grid-item>
                    <tm-grid-item img-src="https://developer.salesforce.com/resources2/tb-connect/s3.png"></tm-grid-item>
                </tm-card-grid-a>
            </tm-section-a>
            
            <tm-section-a title="Meet Trailblazers Transforming Their Careers">
                <tm-quotes-a>
                    <tm-quote 
                        author="Daphne Zhang" 
                        img-alt="Daphne Zhang photo" 
                        img-src="https://developer.salesforce.com/resources2/tb-connect/dz.png" 
                        role="Project Manager, Arkus, Inc." 
                        text="With the help of a Trailblazer Mentor, I was offered a new role and got encouragement during each phase of the hiring process. I cannot recommend this program enough."></tm-quote>
                        
                    <tm-quote 
                        author="Christy Smith" 
                        img-alt="Christy Smith photo" 
                        img-src="https://developer.salesforce.com/resources2/tb-connect/cs.png" 
                        role="Salesforce Admin Apprentice, Blind Institute of Technology" 
                        text="Breaking into tech as a blind person seemed impossible. I’m now a Salesforce certified Admin and Salesforce training has changed my resume and my life."></tm-quote>
                    
                    <tm-quote 
                        author="Dominic Bocanegra" 
                        img-alt="Dominic Bocanegra photo" 
                        img-src="https://developer.salesforce.com/resources2/tb-connect/dm.png" 
                        role="Fundamentals Participant" 
                        text="Salesforce Fundamentals is the best program - at no cost. I met amazing mentors, learned hands-on skills, and earned my Associate Admin certification. I finally found a long-term career with Salesforce."></tm-quote>
                        
                    <tm-quote 
                        author="Jessica Mills" 
                        img-alt="Jessica Mill photo" 
                        img-src="https://developer.salesforce.com/resources2/tb-connect/jm.png" 
                        role="Salesforce Consultant, Fast Slow Motion" 
                        text="I met my current employer at a Trailblazer Career Fair. I found roles for my experience level and asked questions that helped me find an employer that matched my values."></tm-quote>
                </tm-quotes-a>
            </tm-section-a>
            
            <tm-section-a background="light" title="Explore More Career Resources">
                <tm-card-grid-a img-aspect="60%" img-type="full">
                    <tm-grid-item 
                        cta-href="https://trailhead.com/career-paths" 
                        cta-target="true" 
                        cta-title="LEARN MORE" 
                        description="Anyone can be a Trailblazer with Salesforce. Find your entry point, learn in-demand skills, and grow your career for free." 
                        img-alt="Explore Career Paths on Trailhead image" 
                        img-src="https://developer.salesforce.com/resources2/tb-connect/Career+Paths+CTA.png" 
                        title="Explore Career Paths on Trailhead"></tm-grid-item>
                    
                    <tm-grid-item 
                        cta-href="https://trailhead.salesforce.com/users/trailblazerconnect/trailmixes/resources-for-job-seekers" 
                        cta-target="true" 
                        cta-title="DIVE IN" 
                        description="Learn more about the resources Trailblazer Connect has available for job seekers." 
                        img-alt="Complete the Job Seeker Resources Trailmix image" 
                        img-src="https://developer.salesforce.com/resources2/tb-connect/Jobseeker+Trailmix+CTA.png?v=1" 
                        title="Complete the Job Seeker Resources Trailmix"></tm-grid-item>
                    
                    <tm-grid-item 
                        cta-href="https://trailhead.salesforce.com/trailblazer-community/" 
                        cta-target="true" 
                        cta-title="SIGN UP" 
                        description="Join a global network of Trailblazers who help you learn relevant skills, connect, and give back together." 
                        img-alt="Join the Trailblazer Community image" 
                        img-src="https://developer.salesforce.com/resources2/tb-connect/TB+Community+CTA.png" 
                        title="Join the Trailblazer Community"></tm-grid-item>
                    
                    <tm-grid-item 
                        cta-href="https://1.salesforce-partners.com/employer-resources" 
                        cta-target="true" 
                        cta-title="JOIN NOW" 
                        description="Are you an employer looking to grow your Salesforce business? Reskill your employees, connect to new Salesforce talent, and develop inclusive hiring practices." 
                        img-alt="Join the Salesforce Talent Alliance image" 
                        img-src="https://developer.salesforce.com/resources2/career-fair/Talent Alliance extra space.png" 
                        title="Join the Salesforce Talent Alliance"></tm-grid-item>
                </tm-card-grid-a>
            </tm-section-a>
        </tm-page>
    </LWCLayout>
  )
}

export default TrailblazerconnectIndex
